import React from "react";

const PRODUCT_LOGOS_IMAGE = require("../images/ET_Product_Logos_Black.png");

export default function ProductLogos({ style }) {
    return (
        <div className="flex-centered" style={{ padding: "1.5vh 0 2.5vh 0" }}>
            <img
                style={{
                    maxWidth: "45vw",
                    ...style,
                }}
                src={PRODUCT_LOGOS_IMAGE}
            />
        </div>
    );
}
