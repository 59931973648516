exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ImageButton__button :hover{transform:scale(1.1);cursor:pointer}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/content/packages/ETSetupWalkthrough/common/ImageButton.scss"],"names":[],"mappings":"AAAA,4BAAe,qBAAqB,cAAc,CAAC","file":"ImageButton.scss","sourcesContent":[".button :hover{transform:scale(1.1);cursor:pointer}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"button": "ImageButton__button"
};