import React from "react";
import PageRoot from "content/packages/ETSetupWalkthrough/pages/PageRoot";
import CalloutBox from "content/packages/ETSetupWalkthrough/common/CalloutBox";
import { LIGHT_GREEN } from "content/packages/ETSetupWalkthrough/constants";
import NextButton from "content/packages/ETSetupWalkthrough/common/NextButton";
import Divider from "content/packages/ETSetupWalkthrough/common/Divider";
import ClientExperienceContainer from "content/packages/ETSetupWalkthrough/common/ClientExperienceContainer";

const APP_LOGIN_IMAGE = require("../images/screenshots/1_login_page.png");
const CLIENT_ID_PIMS_IMAGE = require("../images/client_id_pims.png");
const CLIENT_ID_INVOICE_IMAGE = require("../images/client_id_invoice.png");

export default function ClientLoginPimsPage({
    onNext = () => undefined,
}) {
    return (
        <PageRoot>
            <ClientExperienceContainer>
                <div className="flex flex-1">
                    {/* Phone */}
                    <div
                        className="flex flex-column flex-centered"
                        style={{ width:"33.3%", alignItems: "center", justifyContent: "flex-start", textAlign: "center", fontSize: "1.3em" }}
                    >
                        <img
                            style={{ width: "100%", maxHeight: "50vh", objectFit: "contain" }}
                            src={APP_LOGIN_IMAGE}
                        />
                    </div>
                    {/* Explainer */}
                    <div
                        className="flex flex-column"
                        style={{ width:"66.6%", alignItems: "center", justifyContent: "flex-start" }}
                    >
                        <div className="full-width padding-right-sm">
                            <h1 style={{ color: LIGHT_GREEN }}>Client login</h1>
                            <p>When your client scans the QR code in your clinic, they will be prompted to input their <b>last name and Client ID</b>.</p>
                            <p>Clients that receive an offer via email will only need to enter their last name.</p>
                        </div>
                        <CalloutBox style={{ marginTop: "4%" }}>
                            <div className="flex-column">
                                <div className="flex-centered margin-bottom-sm">
                                    <h2>Where do I find client ID in my PIMS?</h2>
                                </div>
                                <div className="flex full-width">
                                    <div className="flex flex-1 flex-centered spaced-content">
                                        <img
                                            style={{ width: "50%", objectFit: "contain" }}
                                            src={CLIENT_ID_PIMS_IMAGE}
                                        />
                                        <div style={{ width: "50%" }}>
                                            <p>In most practice management software, the client ID is found in the upper right or left hand corner.</p>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className="flex flex-1 flex-centered spaced-content">
                                        <img
                                            style={{ width: "50%", objectFit: "contain" }}
                                            src={CLIENT_ID_INVOICE_IMAGE}
                                        />
                                        <div style={{ width: "50%" }}>
                                            <p>A client ID can also be found in their most recent invoice.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CalloutBox>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                                width: "100%",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                            }}
                        >
                            <NextButton onNext={onNext} />
                        </div>
                    </div>
                </div>
            </ClientExperienceContainer>
        </PageRoot>
    );
}
