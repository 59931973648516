import React from "react";

export function getWalkthroughId(programId) {

    switch (programId) {
        case 7:
            // Eligibility Tracker
            return "etWalkThru";
        default:
            return null;
    }
}

export const ET_FAQ_QUESTIONS = [
    {
        q: "What happens if client does not scan the materials or have an emailed offer?",
        a: (
            <ul>
                <li>No problem! All pet owners are eligible for the regular discounts offered on BI products.</li>
            </ul>
        )
    },
    {
        q: "How do I help clients who do not have a phone but want to see if they have a special offer?",
        a: (
            <ul>
                <li>In your Greenline portal, there is a section for Eligible Clients you at the clinic can reference if needed.</li>
            </ul>
        )
    },
    {
        q: "What if a pet owner asks about an offer that they are not eligible for?",
        a: (
            <ul>
                <li>Advise the client that "the specific offer value will vary depending on your individual purchase history of BI brands,  but there is always an offer available to you." Then reiterate the benefits of the product with the pet owner as well as the benefits of purchasing through your clinic.</li>
                <li>If the pet owner is upset or extremely insistent, the high-value offer can be given but this should only be done on an exception basis. These instances will be tracked throughout the program.</li>
            </ul>
        )
    },
    {
        q: "What if my clinic gives the wrong offer by mistake?",
        a: (
            <ul>
                <li>The Greenline system is tracking offer eligibility and will flag offers given to ineligible pet owners. These will show up in Stray coupons and will require review.</li>
            </ul>
        )
    },
    {
        q: "I cannot edit discount amounts when checking out a client. Do I need to create rebate codes for all offers?",
        a: (
            <ul>
                <li>Yes, if you cannot edit a singular price due to restrictions with your software, Greenline will assist in making all necessary codes.</li>
            </ul>
        )
    },
    {
        q: "What if a pet owner purchases through home delivery?",
        a: (
            <ul>
                <li>Pet owners can take advantage of the regular offers and autoship discounts available through Home Delivery, but the offers shown in their Personalized Offers portal are only available on in-clinic purchases and may differ from offers available online.</li>
            </ul>
        )
    },
    {
        q: "How does my staff know that a client is showing a valid offer?",
        a: (
            <ul>
                <li>There are two ways:</li>
                <ol>
                    <li>The client can show the staff the offer on their phone from the personalized offer interface.</li>
                    <li>The staff can reference the Greenline portal eligible client interface to see what offers the client is eligible for.</li>
                </ol>
            </ul>
        )
    },
    {
        q: "Will new clients have offers available to them?",
        a: (
            <ul>
                <li>Yes, all pet owners are eligible for the regular discounts offered on BI products.</li>
            </ul>
        )
    },
    {
        q: "What brands will have offers featured on the Personalized Offers portal?",
        a: (
            <ul>
                <li>NexGard® PLUS, NexGard® COMBO, NexGard® and HEARTGARD® Plus</li>
            </ul>
        )
    }
];
