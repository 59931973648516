import React from "react";
import ImageButton from "content/packages/ETSetupWalkthrough/common/ImageButton";

const NEXT_IMAGE = require("../images/next_arrow.png");

export default function NextButton({
    image = NEXT_IMAGE,
    imageStyle = {},
    altText = "Next button",
    containerStyle = {},
    onNext = () => undefined,
}) {
    return (
        <div style={{
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "flex-end",
            marginTop: "4%",
            ...containerStyle,
        }}>
            <div style={{
                width: "50%",
                paddingRight: "2%",
                ...imageStyle,
            }}>
                <ImageButton
                    source={image}
                    altText={altText}
                    onClick={onNext}
                />
            </div>
        </div>
    );
}
