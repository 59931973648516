import React from "react";

export default function PageRoot({
    style = {},
    children,
}) {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            backgroundColor: "white",
            ...style,
        }}>
            {children}
        </div>
    );
}
