import logger from "utils/logger";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import map from "lodash/map";

export const isEmailValid = email => {
    return /^\S+@\S+\.\S+$/.test(email);
}

export function parseHtml(htmlString) {
    const parser = new DOMParser()
    const tags = htmlString.match(/(?!<)(\w+)(?=>)/);
    const firstTag = tags[0];
    const htmlElements = parser.parseFromString(htmlString, 'text/html');
    const scriptTags = htmlElements.getElementsByTagName("script");
    const scriptsToLoad = filter(scriptTags, script => !!script.src);
    const scriptsToInject = filter(scriptTags, script => !script.src);

    const scripts = map(scriptsToLoad, script => script.src);
    const inlineScripts = map(scriptsToInject, script => {
       return script.textContent;
    });
    forEach(scriptTags, script => {
        if (script) {
            script.remove();
        }
    });

    const nodes = htmlElements.getElementsByTagName(firstTag)[0];
    return {
        scripts,
        inlineScripts,
        nodes
    }
}
