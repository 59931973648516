import React from "react";
import { LIGHT_GREEN } from "content/packages/ETSetupWalkthrough/constants";
import PageRoot from "content/packages/ETSetupWalkthrough/pages/PageRoot";
import NextButton from "content/packages/ETSetupWalkthrough/common/NextButton";
import ClientExperienceContainer from "content/packages/ETSetupWalkthrough/common/ClientExperienceContainer";

const APP_SCREEN_IMAGE = require("../images/screenshots/4_offer_page.png");

export default function InstantRebatesPage({
    onNext = () => undefined,
}) {
    return (
        <PageRoot>
            <ClientExperienceContainer>
                <div className="flex flex-1">
                    <div
                        className="flex flex-column flex-centered"
                        style={{ width: "33.3%", alignItems: "center", justifyContent: "flex-start", textAlign: "center", fontSize: "1.3em" }}
                    >
                        <img
                            style={{ width: "100%", maxHeight: "50vh", objectFit: "contain" }}
                            src={APP_SCREEN_IMAGE}
                        />
                    </div>
                    <div
                        className="flex flex-column"
                        style={{ width: "66.6%", alignItems: "center", justifyContent: "flex-start" }}
                    >
                        <div className="full-width padding-right-sm">
                            <h1 style={{ color: LIGHT_GREEN }}>Instant rebates</h1>
                            <p>Your client can choose which offer they would like to redeem and will need to show the offer to your staff during the checkout process.</p>
                            <p><b>Clinic Staff:</b></p>
                            <ol>
                                <li>Apply the NGHGB (NexGard&reg;/HEARTGARD&reg; Brand Offer) code on the invoice.</li>
                                <li>Adjust the discount amount to match the offer shown by the client and ensure they are purchasing the qualifying brand and quantity.</li>
                            </ol>
                        </div>
                        <div
                            className="flex-1"
                            style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-end", justifyContent: "flex-end" }}
                        >
                            <NextButton onNext={onNext} />
                        </div>
                    </div>
                </div>
            </ClientExperienceContainer>
        </PageRoot>
    );
}
