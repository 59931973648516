import React from "react";
import PageFooter from "content/packages/ETSetupWalkthrough/common/PageFooter";
import ImageButton from "content/packages/ETSetupWalkthrough/common/ImageButton";
import PageRoot from "content/packages/ETSetupWalkthrough/pages/PageRoot";
import { DARK_GREEN, LIGHT_GREEN } from "content/packages/ETSetupWalkthrough/constants";
import Divider from "content/packages/ETSetupWalkthrough/common/Divider";

const JOIN_THE_GAME_IMAGE = require("../images/ET_WalkThrough_Pets.png");
const NEXT_BUTTON_IMAGE = require("../images/learn_more_arrow.png");

export default function JoinTheGamePage({
    onNext = () => undefined,
}) {
    return (
        <PageRoot style={{ fontSize: "1.2vw", maxHeight: "100%", backgroundColor: DARK_GREEN }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                width: "100%",
                backgroundColor: LIGHT_GREEN,
            }}>
                <div style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    justifyContent: "flex-end",
                    width: "100%",
                    alignItems: "center",
                }}>
                    <img
                        style={{
                            width: "auto",
                            maxHeight: "50vh",
                            objectFit: "contain",
                        }}
                        src={JOIN_THE_GAME_IMAGE}
                        alt="Personalized consumer rebates from Boehringer Ingelheim."
                    />
                    <ImageButton
                        style={{ position: "absolute", right: "5%", bottom: "25%", width: "12%" }}
                        source={NEXT_BUTTON_IMAGE}
                        altText="Next button"
                        onClick={onNext}
                    />
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}>
                <PageFooter>
                    <div className="flex spaced-content" style={{ fontSize: "1.1vw" }}>
                        <div>
                            <div style={{ fontWeight: "bold", color: LIGHT_GREEN }}>Pet owner benefits</div>
                            <div>
                                Personalized Consumer Rebates help your clients easily take advantage of instant savings on protection for their pets - all while saving even more.
                            </div>
                        </div>
                        <Divider />
                        <div>
                            <div style={{ fontWeight: "bold", color: LIGHT_GREEN }}>Clinic benefits</div>
                            <div>
                                Personalized Consumer Rebates allows your clinic to offer exclusive savings to help increase compliance and protect more pets - all with fewer rebate codes for your team to manage.
                            </div>
                        </div>
                    </div>
                    <div className="margin-top-sm">
                        <div>
                            <b>IMPORTANT SAFETY INFORMATION:</b>
                        </div>
                        <div>
                            <span style={{ textWrap: "nowrap" }}>NexGard&reg; (afoxolaner) and NexGard&reg; PLUS (afoxolaner, moxidectin, and pyrantel chewable tablets) </span> are for use in dogs only. Use with caution in dogs with a history of seizures or neurologic disorders. For more information, including full prescribing information, call 888-637-4251,
                            or visit <a href="https://nexgardforpets.com" target="_blank" style={{ color: LIGHT_GREEN }}>NexGardforpets.com</a> or <a href="https://nexgardplus.com" target="_blank" style={{ color: LIGHT_GREEN }}>NexGardPLUS.com</a>.
                        </div>
                        <div className="margin-top-sm">
                            <span style={{ textWrap: "nowrap" }}>NexGard&reg; COMBO (esafoxolaner, eprinomectin, and praziquantel topical solution) </span> is for topical use only in cats. Use with caution in cats with a history of seizures or neurologic disorders. For more information, including full prescribing information, call 888-637-4251,
                            or visit <a href="https://nexgardcombo.com" target="_blank" style={{ color: LIGHT_GREEN }}>NexGardCOMBO.com</a>.
                        </div>
                        <div className="margin-top-sm">
                            Following the use of <span style={{ textWrap: "nowrap" }}>HEARTGARD&reg; Plus (ivermectin/pyrantel) </span> in dogs, digestive and neurologic side effects and itching have been reported. All dogs should be tested for heartworm infection before starting a preventive program. For more information or for the full prescribing information, call 888-637-4251,
                            or visit <a href="https://heartgard.com" target="_blank" style={{ color: LIGHT_GREEN }}>HEARTGARD.com</a>.
                        </div>
                        <div className="margin-top-sm">
                            NexGard&reg; is a registered trademark and NexGard COMBO&trade; is a trademark of Boehringer Ingelheim Animal Health France, used under license. HEARTGARD&reg; is a registered trademark of Boehringer Ingelheim Animal Health USA Inc. All other trademarks shown are property of their respective owner. &copy;2024 Boehringer Ingelheim Animal Health USA Inc. All rights reserved.
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        US-PET-0864-2024
                    </div>
                </PageFooter>
            </div>
        </PageRoot>
    );
}
