exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".WalkthroughContainer__walkthroughContainer{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex:1;flex:1;background-color:#004B3D}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/content/packages/ETSetupWalkthrough/WalkthroughContainer.scss"],"names":[],"mappings":"AAAA,4CAAsB,kBAAkB,oBAAoB,aAAa,0BAA0B,sBAAsB,WAAW,OAAO,wBAAwB,CAAC","file":"WalkthroughContainer.scss","sourcesContent":[".walkthroughContainer{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex:1;flex:1;background-color:#004B3D}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"walkthroughContainer": "WalkthroughContainer__walkthroughContainer"
};