import React from "react";
import WalkthroughContainer from "content/packages/ETSetupWalkthrough/WalkthroughContainer";
import EtSetupWalkthroughController from "content/packages/ETSetupWalkthrough/EtSetupWalkthroughController";

/** This exists so we can view it within the main website while developing. The real bundle root is `ETSetupWalkthrough.jsx`. */
export default function WalkthroughDevContainer() {
    return (
        <WalkthroughContainer>
            <EtSetupWalkthroughController />
        </WalkthroughContainer>
    );
}
