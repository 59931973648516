import React from "react";
import styles from "./WalkthroughContainer.scss";

export default function WalkthroughContainer({ children }) {
    return (
        <div className={styles.walkthroughContainer}>
            {children}
        </div>
    );
}
