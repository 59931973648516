import React from "react";
import { LIGHT_GREEN } from "content/packages/ETSetupWalkthrough/constants";
import PageRoot from "content/packages/ETSetupWalkthrough/pages/PageRoot";
import NextButton from "content/packages/ETSetupWalkthrough/common/NextButton";
import ClientExperienceContainer from "content/packages/ETSetupWalkthrough/common/ClientExperienceContainer";

const APP_SCREEN_IMAGE = require("../images/screenshots/3_dog_selected.png");

export default function SpecializedOffersPage({
    onNext = () => undefined,
}) {
    return (
        <PageRoot>
            <ClientExperienceContainer>
                <div className="flex flex-1">
                    <div
                        className="flex flex-column flex-centered"
                        style={{ width: "33.3%", alignItems: "center", justifyContent: "flex-start", textAlign: "center", fontSize: "1.3em" }}
                    >
                        <img
                            style={{ width: "100%", maxHeight: "50vh", objectFit: "contain" }}
                            src={APP_SCREEN_IMAGE}
                        />
                    </div>
                    <div
                        className="flex flex-3 flex-column"
                        style={{ width: "66.6%", alignItems: "center", justifyContent: "flex-start" }}
                    >
                        <div className="full-width padding-right-sm">
                            <h1 style={{ color: LIGHT_GREEN }}>Specialized offers</h1>
                            <p>Your client will be shown 1 or 2 offers specifically tailored to their needs, and to help you emphasize the value they get when they purchase from your clinic.</p>
                        </div>
                        <div
                            className="flex-1"
                            style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-end", justifyContent: "flex-end" }}
                        >
                            <NextButton onNext={onNext} />
                        </div>
                    </div>
                </div>
            </ClientExperienceContainer>
        </PageRoot>
    );
}
