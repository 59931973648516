import React from "react";
import styles from "./ImageButton.scss";

export default function ImageButton({
    source,
    altText = "Button",
    onClick = () => undefined,
    style,
}) {
    return (
        <div
            className={styles.button}
            style={style}
            onClick={onClick}
        >
            <img
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    transition: "all 0.1s ease-in-out",
                }}
                src={source}
                alt={altText}
            />
        </div>
    );
}
