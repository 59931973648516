import React, { useState } from "react";
import PageStepper from "content/packages/ETSetupWalkthrough/PageStepper";
import JoinTheGamePage from "content/packages/ETSetupWalkthrough/pages/JoinTheGamePage";
import EnrollPage from "content/packages/ETSetupWalkthrough/pages/EnrollPage";
import ClientLoginPimsPage from "content/packages/ETSetupWalkthrough/pages/ClientLoginPimsPage";
import PetViewPage from "content/packages/ETSetupWalkthrough/pages/PetViewPage";
import SpecializedOffersPage from "content/packages/ETSetupWalkthrough/pages/SpecializedOffersPage";
import InstantRebatesPage from "content/packages/ETSetupWalkthrough/pages/InstantRebatesPage";

const SEQUENCES = [
    {
        pages: [
            JoinTheGamePage,
        ],
    },
    {
        pages: [
            EnrollPage,
            ClientLoginPimsPage,
            PetViewPage,
            SpecializedOffersPage,
            InstantRebatesPage,
        ],
        isLooping: true,
    },
];

export default function EtSetupWalkthroughController() {
    const [sequenceIndex, setSequenceIndex] = useState(0);

    const handleComplete = () => {
        setSequenceIndex(sequenceIndex + 1);
    };

    const handleEnroll = () => {
        if (!!window.onEtSetupEnroll) { // Main site needs to create this function on window for us to call.
            window.onEtSetupEnroll();
        } else {
            console.log("No 'onEtSetupEnroll' function found on window.");
        }
    };

    const sequence = SEQUENCES[sequenceIndex];

    return (
        <div className="flex flex-column flex-1">
            <PageStepper
                pages={sequence.pages}
                pageProps={{
                    onEnroll: handleEnroll,
                }}
                onComplete={sequence.isLooping ? undefined : handleComplete}
            />
        </div>
    );
}
