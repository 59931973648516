import React, { useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import includes from "lodash/includes";
import * as styles from "./ProgramTile.scss";
import InformationTile from "components/common/InformationTile";
import ProgramOptInDetailsModal from "components/clinic/elements/ProgramOptInDetailsModal";
import { DANGER, GRAY, INACTIVE, SUCCESS } from "constants/CssStyleIds";
import { CANCELED, DECLINED, ELIGIBLE, ENROLLED, INELIGIBLE } from "constants/ProgramStatuses";

export default function ProgramTile({
    canEditProgramEnrollmentClinic = false,
    clinicId,
    id,
    imageStyle = {},
    parentProgram,
    style ={},
    tile,
}) {
    const history = useHistory();
    const params = useParams();
    const { pathname } = useLocation();
	const pathClinicId = Number(params.clinicId);
	const nodeId = Number(params.nodeId);
    const isAdmin = pathClinicId && includes(pathname, "/admin");
    const isProvider = nodeId && includes(pathname, "/provider");
    // If is an admin then append some string to beginning of URL
	const linkBase = isAdmin ? `/admin/clinic/${pathClinicId}` : isProvider ? `/provider/location/${nodeId}/clinic/${pathClinicId}` : '';
    const [showModal, setShowModal] = useState(false);
    const tileStyle = (
        tile.status === CANCELED ? INACTIVE :
        tile.status === DECLINED ? DANGER :
        tile.status === ELIGIBLE ? SUCCESS :
        tile.status === INELIGIBLE ? GRAY :
        null
    );

    const handleTileClicked = async () => {
        // Condition 1: They acceptedTerms at some point OR they are NOT INELIGIBLE
        if (tile.acceptedTerms || tile.status !== INELIGIBLE) {
            // Condition 2: They are ENROLLED currently OR they are now INELIGIBLE
            if (tile.status === ENROLLED || tile.status === INELIGIBLE) {
                // GO TO THE PAGE...
                if (parentProgram) {
                    history.push(`${linkBase}/programs/${parentProgram}/service/${tile.id}`);
                } else {
                    history.push(`${linkBase}/programs/${tile.id}`);
                }
            } else if (canEditProgramEnrollmentClinic) {
                //  Condition 3: They are not ENROLLED nor INELIGIBLE but can edit their enrollment...
                setShowModal(true);
            }
        }
    };

    return (
        <>
            <div
                className={classnames(styles.tile, {
                    [styles.disabled]: !(tile.status === ENROLLED || canEditProgramEnrollmentClinic) || (tile.status === INELIGIBLE && !tile.acceptedTerms),
                })}
                style={style}
                id={id}
                onClick={handleTileClicked}
            >
                <div>
                    <InformationTile
                        title={tile.name}
                        // TODO: Could use the badge to indicate if there are any services that have not yet been enrolled in
                        // badge={badge}
                        footer={(
                            <div>{tile.status}{tile.userActionDate ? `: ${moment(tile.userActionDate).format("MM/DD/YYYY")}` : ""} </div>
                        )}
                        style={tileStyle}
                    >
                        <img
                            alt={tile.name}
                            src={tile.image}
                            title={tile.name}
                            style={imageStyle}
                        />
                    </InformationTile>
                </div>
            </div>
            <ProgramOptInDetailsModal
                key={`Tile_${tile.id}`}
                show={showModal}
                onClose={() => setShowModal(false)}
                program={tile}
                programId={tile.id}
                clinicId={clinicId}
                parentProgram={parentProgram}
            />
        </>
    )
}

ProgramTile.propTypes = {
    canEditProgramEnrollmentClinic: PropTypes.bool,
    clinicId: PropTypes.number.isRequired,
    id: PropTypes.string,
    imageStyle: PropTypes.object,
    parentProgram: PropTypes.number,
    style: PropTypes.object,
    tile: PropTypes.shape({}).isRequired,
};
