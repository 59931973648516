import React from "react";
import PageRoot from "content/packages/ETSetupWalkthrough/pages/PageRoot";
import PageTitle from "content/packages/ETSetupWalkthrough/common/PageTitle";
import ProductLogos from "content/packages/ETSetupWalkthrough/common/ProductLogos";
import BiFooter from "content/packages/ETSetupWalkthrough/common/BiFooter";

export default function ClientExperienceContainer({
    pageTitle = "How do Personalized Offers Work?",
    children,
}) {
    return (
        <PageRoot style={{ fontSize: "1.6vh" }}>
            <div style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                flex: 1,
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                }}>
                    <PageTitle title={pageTitle} />
                    <ProductLogos />
                    {children}
                </div>
                <div className="width-100-percent">
                    <BiFooter />
                </div>
            </div>
        </PageRoot>
    );
}
