import React, { useRef, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./ProviderClinicGamePage.scss";
import * as ClinicActions from "actions/ClinicActions";
import AccessDenied from "components/common/AccessDenied";
import BiProductDisclosures from "components/common/BiProductDisclosures";
import GenericGame from "components/games/elements/GenericGame/GenericGame";
import GenericGame2024 from "components/games/elements/GenericGame/GenericGame2024";
import { GoldenSummerLeaderboardLegalWording } from "components/games/elements/LegalWording/GoldenSummerLegalWording";
import Loader from "components/common/Loader";
import { PuppyAndKittyBowlLeaderboardLegalWording } from "components/games/elements/LegalWording";
import PrintContentContainer from "components/common/PrintContentContainer/PrintContentContainer";
import ProviderBase from "components/layout/ProviderBase";
import PuppyBowlGame from "components/games/pages/ClinicGamePage/PuppyBowlGame";
import PuppyLeafParty from "components/games/pages/ClinicGamePage/PuppyLeafParty";
import PuppyPoolParty from "components/games/pages/ClinicGamePage/PuppyPoolParty";
import PuppyBowl2 from "components/games/pages/ClinicGamePage/PuppyBowl2";
import { getClinicGameById } from "utils/ClinicData";
import getPrimaryClinicIdForUser from "utils/getPrimaryClinicIdForUser";
import { PermissionTypes, userHasPermission, userPrimaryLocationType } from "utils/permissions/rolesPermissions";
import { CLINIC_TAGS } from "constants/AdminControls";
import {
    GOLDEN_SUMMER,
    GRAND_SLAM,
    LEAF_PARTY,
    PUPPY_BOWL,
    PUPPY_BOWL_2,
    PUPPY_POOL_PARTY,
    PRINTABLE_GAMES,
} from "constants/Games/GameConstants";
import * as UserPermissions from "constants/UserPermissions";
import * as LocationTypes from "constants/LocationTypes";
import GoldenSummerGames from "components/games/pages/ClinicGamePage/GoldenSummerGames";
import GrandSlamGame from "../../games/pages/ClinicGamePage/GrandSlamGame";
import {GrandSlamLeaderboardLegalWording} from "../../games/elements/LegalWording/GrandSlamLegalWording";

function PrintProviderGamePage(props) {
    const {
        game,
        isProvider = false,
        printableContentRef,
    } = props;

    const params = useParams();
    const clinicId = Number(params.clinicId);
    const [printing, setPrinting] = useState(false);

    const gameProperties = useMemo(() => {
        if (game) {
            switch (game.gameType) {
                case PUPPY_BOWL_2:
                    return {
                        bannerUrl: "https://cdn.greenlinepet.com/games/assets/petsbowl/logos/Clinic_Rep_Leaderboard_Pets_Bowl_Banner_Image.jpg",
                        bannerAlt: "HeartGard® Plus+ and NexGard® Plus+ advertisement",
                        board: PuppyBowl2,
                        legalWording: PuppyAndKittyBowlLeaderboardLegalWording,
                        usePoints: false,

                    };
                case GOLDEN_SUMMER:
                    return {
                        bannerUrl: "https://cdn.greenlinepet.com/games/assets/GoldenGames/GoldenGames Banner_970x90_r1v1.jpg",
                        bannerAlt: "Golden Summer Games",
                        board: GoldenSummerGames,
                        legalWording: GoldenSummerLeaderboardLegalWording,
                        usePoints: true,
                    };
                case GRAND_SLAM:
                    return {
                        bannerUrl: "https://cdn.greenlinepet.com/games/assets/GrandSlam/BIAHU-004763_CMK_2024_FallGreenlineGames_Banners_970x89.jpg",
                        bannerAlt: "Grand Slam Game",
                        board: GrandSlamGame,
                        legalWording: GrandSlamLeaderboardLegalWording,
                        usePoints: true,
                    };
                default:
                // Do nothing
            }
        }
        return null;
    }, [game]);

    const renderGameSection = () => {
        switch(game.gameType) {
            case PUPPY_BOWL:
                return (
                    <PuppyBowlGame
                        game={game}
                        clinicId={clinicId}
                        animate
                    />
                );
            case PUPPY_POOL_PARTY:
                return (
                    <PuppyPoolParty
                        game={game}
                        clinicId={clinicId}
                        animate={!printing}
                    />
                );
            case LEAF_PARTY:
                return (
                    <PuppyLeafParty
                        animate={!printing}
                        clinicId={clinicId}
                        game={game}
                        isProvider={isProvider}
                        printing={printing}
                        snapshot
                    />
                );
            case PUPPY_BOWL_2:
            case GOLDEN_SUMMER:
                return (
                    <GenericGame
                        animate={!printing}
                        clinicId={clinicId}
                        game={game}
                        bannerAd={gameProperties.bannerUrl}
                        bannerAdAltText={gameProperties.bannerAlt}
                        Leaderboard={gameProperties.board}
                        LegalWording={gameProperties.legalWording}
                        printing={printing}
                        usePoints={gameProperties.usePoints}
                    />
                );
            case GRAND_SLAM:
                return (
                    <GenericGame2024
                        animate={!printing}
                        clinicId={clinicId}
                        game={game}
                        bannerAd={gameProperties.bannerUrl}
                        bannerAdAltText={gameProperties.bannerAlt}
                        Leaderboard={gameProperties.board}
                        LegalWording={gameProperties.legalWording}
                        printing={printing}
                        usePoints={gameProperties.usePoints}
                        onlyPaid
                        isProvider
                    />
                );
            default:
                return <div>NOT YET IMPLEMENTED</div>;
        }
    }

    return (
        <PrintContentContainer
            onSetIsPrinting={setPrinting}
            allowPrint={PRINTABLE_GAMES[game.gameType]}
            outsideContentRef={printableContentRef}
        >
            <div className={styles.content}>
                {renderGameSection()}
            </div>
        </PrintContentContainer>
    );
}

function ProviderClinicGamePage(props) {
    const {
        canViewClinicBIGamesDashboard = false,
        game,
        getClinicGreenlineGames,
    } = props;

	const printableContentRef = useRef();
	const params = useParams();
	const nodeId = Number(params.nodeId);
	const providerId = Number(params.providerId);
	const clinicId = Number(params.clinicId);

    useEffect(() => {
        if (canViewClinicBIGamesDashboard && !game) {
            getClinicGreenlineGames(clinicId);
        }
    }, [canViewClinicBIGamesDashboard, game, clinicId]);

    if (!game) {
        return <Loader />
    }

    return (
        <ProviderBase
            pageTitle={game?.name || "Greenline Game"}
            clinicId={clinicId}
            nodeId={nodeId}
            providerId={providerId}
            adminControls={CLINIC_TAGS}
            collapsible
        >
            <div className={styles.root}>
                {canViewClinicBIGamesDashboard ? (
                    <div ref={printableContentRef}>
                        <PrintProviderGamePage
                            {...props}
                            printableContentRef={printableContentRef}
                        />
                        <div className="text-x-sm border-top margin-bottom-sm">
                            <BiProductDisclosures/>
                        </div>
                    </div>
                ) : (
                    <AccessDenied/>
                )}
            </div>
        </ProviderBase>
    );
}

export default connect(
    (state, ownProps) => {
        const gameId = Number(ownProps.match.params.gameId);
        const userProfile = state.user.userProfile;
        const isProvider = userPrimaryLocationType(userProfile, [LocationTypes.PROVIDER]);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            canViewClinicBIGamesDashboard,
            game: getClinicGameById(state, gameId),
            gameId,
            isProvider,
            snapshots: state.entities.mostRecentGameSnapshotDate,
        }
    },
    (dispatch) => ({
        getClinicGreenlineGames: (clinicId) => dispatch(ClinicActions.getGreenlineGames(clinicId)),
    }),
)(ProviderClinicGamePage);
