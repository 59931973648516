import React from "react";
import styles from "./Divider.scss";

/** Vertical divider. */
export default function Divider({
    containerStyle = {},
    lineStyle = {},
}) {
    return (
        <div style={{ display: "flex", alignItems: "center", marginLeft: "2%", marginRight: "2%", ...containerStyle }}>
            <div
                className={styles.divider}
                style={lineStyle}
            />
        </div>
    );
}
