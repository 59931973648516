import React, { useState } from "react";
import PropTypes from "prop-types";
import CheckboxInput from "components/common/CheckboxInput";
import Image from "components/common/Image";
import TextBox from "components/common/TextBox";
import NotificationButtons from "components/notifications/elements/NotificationButtons";
import UserInitials from "components/notifications/elements/UserInitials";
import { DECLINED, ENROLLED } from "constants/Notifications";
import { isEmailValid } from "utils/helperUtils";

export default function VetCheckOfferForm(props) {
    const [formData, setFormData] = useState({});

    const handleFormChange = ({ name, value }) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        props.onEnroll(formData.initials, formData);
    };

    const handleDecline = () => {
        props.onDecline();
    };

    const allRequired = formData.SignUpName &&
        isEmailValid(formData.SignUpEmail) &&
        formData.SignUpPhoneNumber &&
        formData.agree &&
        formData.initials;
    const isEnrolled = props.enrollmentState === ENROLLED;
    const isDeclined = props.enrollmentState === DECLINED;

    return (
        <form onSubmit={handleSubmit}>
            <div className="full-width full-height">
                <div>
                    <div className="full-width">
                        <Image
                            src="https://cdn.greenlinepet.com/images/partnerprograms/VetCheck/logo_vetcheck_automate_header-Filled.png"
                            alt="VetCheck Logo"
                            style={{maxWidth: "100%"}}
                        />
                    </div>
                    <div className="margin-md">
                        <div className="margin-bottom-sm">VetCheck Automate is the automation platform teams use to save time, better educate clients, and provide high-value healthcare.</div>
                        <ul className="margin-bottom-sm">
                            <li>Improve practice workflow efficiency and data collection with digital forms with e-signature</li>
                            <li>Enhance pet health outcomes and client understanding with 100's of home care videos</li>
                            <li>Increase compliance & the uptake of the vet recommendations with 2000 up-to-date, visual client education templates</li>
                        </ul>
                        <div>Automate communication across the entire patient care journey and start building stronger client relationships.</div>
                    </div>
                    <div className="full-width">
                        <Image
                            src="https://cdn.greenlinepet.com/images/partnerprograms/VetCheck/vetcheck-flowchart.jpg"
                            alt="VetCheck flowchart"
                            style={{maxWidth: "100%"}}
                        />
                    </div>
                    <div className="margin-md">
                        <div>
                            <p>How does it work?</p>
                            <p>Our team will work with you to set up your practice workflows so that you can automatically start sharing client communications at the right time with no effort at all.</p>
                        </div>
                        <div>
                            <p>Why VetCheck?</p>
                            <p>
                                Client communication is challenging.
                                Inadequate client communication is the #1 cause for complaint, dealing with clients is the #2 cause for burnout in practice, and a recent survey revealed that 2 out of 3 technicians found client communication the hardest part of their job.
                            </p>
                        </div>
                        <div>VetCheck is your safety net and makes life easier for the whole veterinary team.</div>
                        <div className="text-center margin-top-md">
                            <a className="text-primary"
                               href="https://vetcheck.it/automate"
                               target="_blank"
                            >
                                VetCheck Automate
                            </a>
                        </div>
                    </div>
                </div>
                <hr/>
                {isEnrolled && <div className="text-center full-width">A Greenline Specialist will reach out.</div>}
                {isDeclined && <div className="text-center full-width">Offer declined.</div>}
                {(!isEnrolled && !isDeclined) &&
                    <>
                        <div className="text-center full-width"><b>If you would like more information, please fill out the form below, and a Greenline Specialist will reach out within 1 business day.</b></div>
                        <div className="flex full-width margin-top-sm">
                            <div className="full-width padding-sm">
                                <div>
                                    <TextBox
                                        required
                                        label="Name"
                                        name="SignUpName"
                                        value={formData.SignUpName}
                                        onChange={handleFormChange}
                                    />
                                </div>
                                <div>
                                    <TextBox
                                        required
                                        label="Email"
                                        name="SignUpEmail"
                                        value={formData.SignUpEmail}
                                        onChange={handleFormChange}
                                    />
                                </div>
                            </div>
                            <div className="full-width padding-sm">
                                <div>
                                    <TextBox
                                        required
                                        label="Phone Number"
                                        name="SignUpPhoneNumber"
                                        value={formData.SignUpPhoneNumber}
                                        onChange={handleFormChange}
                                    />
                                </div>
                                <div>
                                    <TextBox
                                        required
                                        label="Notes"
                                        name="SignUpNotes"
                                        value={formData.SignUpNotes}
                                        onChange={handleFormChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-center align-center margin-top-md">
                            <div className="flex align-center margin-right-lg">
                                <CheckboxInput
                                    name="agree"
                                    checked={formData.agree}
                                    onChange={handleFormChange}
                                />
                                I authorize this service and agree to the terms above.
                            </div>
                            <div>
                                <UserInitials
                                    small
                                    onChange={handleFormChange}
                                    value={formData.initials}
                                />
                            </div>
                        </div>

                        <div className="full-width margin-top-md">
                            <div className="text-center">All required fields must be filled in before accepting</div>
                            <div className="margin-top-sm">
                                <NotificationButtons
                                    notificationDetails={props.notificationDetails}
                                    onDecline={handleDecline}
                                    onDismiss={props.onDismiss}
                                    enrollDisabled={!allRequired}
                                    isPreview={props.isPreview}
                                />
                            </div>
                        </div>

                        <div className="margin-top-lg text-center text-italic" style={{ color: "#888", fontSize: "0.9em" }}>
                            Note: IP address and logged in user will be associated with accepting this agreement
                        </div>
                    </>
                }
            </div>
        </form>
    );
};

VetCheckOfferForm.propTypes = {
    notificationDetails: PropTypes.object,
    enrollmentState: PropTypes.string,
    onEnroll: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    onDismiss: PropTypes.func,
    isPreview: PropTypes.bool
};
