import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ProgramWalkThroughComponent.scss";
import { getWalkthroughId } from "constants/ProgramConstants";
import { getClinicInfo } from "utils/ClinicData";
import {parseHtml} from "utils/helperUtils";
import logger from "utils/logger";
import * as ProgramActions from "../../../actions/ProgramActions";
import forEach from "lodash/forEach";
import SpinnerTakeover from "../../common/SpinnerTakeover";

function ProgramWalkThroughComponent({
    walkThruId,
}) {
    // Walk Through Code
    const [scriptsLoaded, setScriptsLoaded] = useState(false);

    useEffect(() => {
        if (!window?.ETSetupWalkthrough) {
            const scriptEl = document.createElement("script");
            // scriptEl.src = "https://glprodcdnst.blob.core.windows.net/content/packages/etSetupWalkthrough/etSetupWalkthrough.bundle.js";
            scriptEl.src = "https://cdn.greenlinepet.com/content/packages/etSetupWalkthrough/etSetupWalkthrough.bundle.js";
            scriptEl.onload = () => {
                setScriptsLoaded(true);
            }
            document.head.appendChild(scriptEl);
        } else {
            setScriptsLoaded(true);
        }
        if (!window?.onEtSetupEnroll) {
            window.onEtSetupEnroll = () => {
                const tile = document.getElementById("program_id_7_tile");
                tile.click();
            }
        }
    }, []);

    // useEffect(() => {
    //     if (programWalkThrough) {
    //         logger.info("ProgramWalkThroughComponent :: effect :: programWalkThrough.html = ", programWalkThrough.html);
    //         const parsedHtml = parseHtml(programWalkThrough.html);
    //         setHtmlCode(parsedHtml);
    //         forEach(parsedHtml.scripts, (src, indx) => {
    //             const scriptId = `${walkThruId}_script_${indx}`;
    //             const existingElement = document.getElementById(scriptId);
    //             logger.info("ProgramWalkThroughComponent :: effect :: existingElement = ", existingElement);
    //             if (existingElement) {
    //                 setScriptsLoaded(true);
    //             } else {
    //                 const scriptEl = document.createElement("script");
    //                 // scriptEl.src = "https://glprodcdnst.blob.core.windows.net/content/packages/etSetupWalkthrough/etSetupWalkthrough.bundle.js";
    //                 scriptEl.src = src;
    //                 scriptEl.id = scriptId;
    //                 scriptEl.onload = () => {
    //                     setScriptsLoaded(true);
    //                 }
    //                 document.head.appendChild(scriptEl);
    //             }
    //         });
    //     }
    // }, [programWalkThrough]);
    useEffect(() => {
        if (scriptsLoaded) {
            if (window.ETSetupWalkthrough) {
                const walkThrough = new window.ETSetupWalkthrough();
                walkThrough.render();
            }
        }
    }, [scriptsLoaded]);

    const walkThruDivId = useMemo(() => {
        switch (walkThruId) {
            case "etWalkThru":
                return "etWalkthru";
            default:
                return walkThruId;
        }
    }, [walkThruId]);

    return (
        <>
            <div id="api_code_container" className={styles.apiCodeWrapper}>
                <div>
                    <div id={walkThruDivId} />
                </div>
            </div>
            {!scriptsLoaded && <SpinnerTakeover show/>}
        </>
    );
}


ProgramWalkThroughComponent.propTypes = {
    walkThruId: PropTypes.string,
}

export default connect(
    (state, ownProps) => ({
        programWalkThrough: state.entities.programWalkThrough[ownProps.walkThruId],
    }),
    (dispatch) => ({
        getProgramWalkThrough: (walkThroughId) => dispatch(ProgramActions.loadProgramWalkThrough(walkThroughId)),
    })
)(ProgramWalkThroughComponent);
