import React from "react";
import styles from "./PageFooter.scss";

export default function PageFooter({
    style,
    children,
}) {
    return (
        <div
            className={styles.pageFooter}
            style={style}
        >
            {children}
        </div>
    );
}
