import React, {useState} from "react";
import PropTypes from "prop-types";
import Dropdown from "components/common/Dropdown";
import TextBox from "components/common/TextBox";
import NotificationButtons from "components/notifications/elements/NotificationButtons";
import { isEmailValid } from "utils/helperUtils";
import {DECLINED, ENROLLED, STATUS_COLOR_CLASS} from "constants/Notifications";

export default function GreenlineGivingForm(props) {
    const [formData, setFormData] = useState({});

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onEnroll(null, formData);
    };

    const handleDecline = () => {
        props.onDecline();
    }

    const handleChange = ({name, value}) => {
        const newFormData = {
            ...formData,
            [name]: value
        }
        setFormData(newFormData);
    }

    const OPTIONS = [{
        name: "0-3",
        value: "0-3"
    }, {
        name: "4-6",
        value: "4-6"
    }, {
        name: "6-10",
        value: "6-10"
    }, {
        name: "> 10",
        value: "> 10"
    }];

    const isEnrolled = props.enrollmentState === ENROLLED;
    const isDeclined = props.enrollmentState === DECLINED;
    const allRequired = formData.quantity && formData.contactName && formData.contactRole && isEmailValid(formData.emailAddress);

    return (
        <form onSubmit={handleSubmit}>
            {isEnrolled && <div className={`${STATUS_COLOR_CLASS[props.enrollmentState]||""} text-center text-lg`}>Your clinic has opted in.</div>}
            {isDeclined && <div className={`${STATUS_COLOR_CLASS[props.enrollmentState]||""} text-center text-lg`}>Your clinic has opted out.</div>}
            {(isEnrolled || isDeclined) && <hr className={"margin-bottom-md margin-top-sm"}/>}
            <>
                <div className="border-bottom">
                    <div className="margin-bottom-sm">
                        Greenline Pet is proud to be able to offer you a new program that will allow your clinic and your clients to support veterinary teams across the country.
                        We are partnering with the Veterinary Hope Foundation to provide the first of its kind client donation program.
                    </div>
                    <div className="margin-bottom-md">
                        We’ve seen your struggles and we want to help.
                        We believe your clients want to help too.
                        With this program, veterinary clinics and clients can unite to address the mental health struggles facing veterinary teams.
                    </div>
                    <h3>Who is Veterinary Hope Foundation</h3>
                    <div className="margin-bottom-sm">
                        The Veterinary Hope Foundation is a nonprofit organization formed in 2021 to address mental health struggles among veterinarians and veterinary teams.
                        Their mission is to protect mental health by building community and providing education and support.
                    </div>
                    <div className="margin-bottom-md">
                        For example, Veterinary Hope Foundation provides six-week-long support groups free every month for veterinarians.
                        These groups are facilitated by a licensed mental health professional and cover such topics as: establishing boundaries, conflict resolution, and health coping mechanisms for stress.
                        As we scale our program, we will begin to offer support groups to practice managers, technicians, and front desk team members as well.
                        Learn more at <a href="https://veterinaryhope.org/" target="_blank" className="text-primary">veterinaryhope.org</a>.
                    </div>

                    <h3>How the Program Works</h3>
                    <div className="margin-bottom-sm">
                        This program will run like other Greenline rebate programs.
                        At check out, simply ask clients if they want to make a $1 donation (or a donation of any amount) to support veterinary mental health.
                        Pet owners will be charged the amount of their donation on the invoice.
                        Greenline will ACH* the total donations collected once monthly to Veterinary Hope Foundation.
                        (ACH will start in 4th QTR 2022)
                    </div>
                    <div className="text-underline margin-bottom-md">
                        If you’d like to support this program, please answer these questions and click "Interested" below and we will send you more information. Thank you so much for your support.
                    </div>
                    <div className="flex spaced-content text-lg margin-bottom-md text-underline">
                        <div className="flex-1">
                            <a
                                href="https://cdn.greenlinepet.com/content/examples/GLG/VHF/About%20Veterinary%20Hope%20Foundation.pdf"
                                target="_blank"
                                className="text-success"
                            >
                                More About Veterinary Hope Foundation
                            </a>
                        </div>
                        <div className="flex-1 text-right">
                            <a
                                href="https://cdn.greenlinepet.com/content/examples/GLG/VHF/About%20This%20Program.pdf"
                                target="_blank"
                                className="text-success"
                            >
                                More About This Program
                            </a>
                        </div>
                    </div>

                    <div className="margin-bottom-sm">
                        <h3>Survey Questions</h3>
                        <Dropdown
                            name="quantity"
                            value={formData.quantity}
                            placeholder="Select the number of vets"
                            label="How many veterinarians work at your practice (include part-time and full-time veterinarians)?"
                            options={OPTIONS}
                            onChange={handleChange}
                            required
                        />
                        <div className="margin-top-sm">
                            <div className="margin-left-sm margin-bottom-x-sm">Who is the best point of contact in your clinic?</div>
                            <div className="flex spaced-content">
                                <TextBox
                                    name="contactName"
                                    value={formData.contactName}
                                    // placeholder="Enter "
                                    label="Name"
                                    onChange={handleChange}
                                    required
                                    alignedLeft
                                />
                                <TextBox
                                    name="contactRole"
                                    value={formData.contactRole}
                                    // placeholder="Enter "
                                    label="Role/Title"
                                    onChange={handleChange}
                                    required
                                    alignedLeft
                                />
                            </div>
                        </div>
                        <TextBox
                            name="emailAddress"
                            value={formData.emailAddress}
                            // placeholder="Enter "
                            label="What is the best email address for us to send info about VHF?"
                            onChange={handleChange}
                            required
                        />
                        <TextBox
                            name="feedback"
                            value={formData.feedback}
                            label="Feedback or questions you have:"
                            onChange={handleChange}
                            textArea
                        />
                    </div>

                    <div className="margin-bottom-md">Thank you for supporting the veterinary profession by supporting this program.</div>

                    <div>Nick Meurrier, EVP</div>
                    <div>Dr. Blair McConnel, VMD MBA, President of the Board of the Veterinary Hope Foundation</div>
                </div>
                <div className="margin-top-md margin-bottom-md">
                    <NotificationButtons
                        notificationDetails={props.notificationDetails}
                        onDecline={handleDecline}
                        onCancel={props.onCancel}
                        onDismiss={props.onDismiss}
                        enrollDisabled={!allRequired}
                        isPreview={props.isPreview}
                    />
                </div>
            </>
        </form>
    )
}

GreenlineGivingForm.propTypes = {
    notification: PropTypes.object,
    enrollmentState: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onEnroll: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    onDismiss: PropTypes.func,
    isPreview: PropTypes.bool
};
