import React, { useEffect, useState } from "react";
import { setUpAnimation } from "utils/animation/animationHelpers";
import MultipleAnimations from "../GenericGame/MultipleAnimations";
import styles from "./InstantReplayAnimation.scss";



const LEADERBOARD_SPRITE_SHEET = "https://cdn.greenlinepet.com/games/assets/GrandSlam/animations/leaderboard_instant_replay_sprite.png";
const LB_W = 336;
const LB_H = 266;

export default function InstantReplayAnimation({

}) {

    const [instantReplayConfig, setInstantReplayConfig] = useState(null);

    useEffect(() => {
        const {
            duration,
            heightScale,
            frames,
        } = setUpAnimation(270, 18, LB_W , LB_H, 15);

        const newConfig = {
            altText: "Leaderboard",
            animationDuration: duration,
            duration: duration,
            canView: true,
            frames,
            lastFrame: frames.length - 1,
            width: `${LB_W}px`,
            height: `${LB_H}px`,
            left: "84.5%", // Container position offset within game animation view.
            top: "38.5%",
            image: LEADERBOARD_SPRITE_SHEET,
            imageFrame: {
                heightScale: 1500,
            },
            isAnimated: true,
            showScore: false,
        };

        setInstantReplayConfig(newConfig);
    }, []);

    if (!instantReplayConfig) {
        return null;
    }

    return (
        <div className={styles.root}>
            <MultipleAnimations
                key={`Animations_InstantReplay`}
                animations={[instantReplayConfig]}
                repeat
            />
        </div>
    );

}
