exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PageFooter__pageFooter{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex:1;flex:1;padding:1%;background-color:#004B3D;color:white;font-size:10px}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/content/packages/ETSetupWalkthrough/common/PageFooter.scss"],"names":[],"mappings":"AAAA,wBAAY,oBAAoB,aAAa,0BAA0B,sBAAsB,WAAW,OAAO,WAAW,yBAAyB,YAAY,cAAc,CAAC","file":"PageFooter.scss","sourcesContent":[".pageFooter{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex:1;flex:1;padding:1%;background-color:#004B3D;color:white;font-size:10px}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"pageFooter": "PageFooter__pageFooter"
};