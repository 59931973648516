import { getFrameDuration, setUpAnimation } from "utils/animation/animationHelpers";

//=----------------------------------------------------------------------------
// Bunch of legacy stuff.
//=----------------------------------------------------------------------------

const SECONDS = 1000;
// Duration of the entire animation.
export const ANIMATION_DURATION = 5 * SECONDS;

//=----------------------------------------------------------------------------
// Defaults for Grand Slam animations
//=----------------------------------------------------------------------------

const DEFAULT_FRAME_COUNT_X = 5; // Grand Slam Sprite Sheets are all 5 frames wide : 1830px
const DEFAULT_FRAME_W = 366;
const DEFAULT_FRAME_H = 250;
const DEFAULT_FRAME_W_PX = `${DEFAULT_FRAME_W}px`;
const DEFAULT_FRAME_H_PX = `${DEFAULT_FRAME_H}px`;

//=----------------------------------------------------------------------------
// Elements
//=----------------------------------------------------------------------------

export const BANNER = "https://cdn.greenlinepet.com/games/assets/GrandSlam/BIAHU-004763_CMK_2024_FallGreenlineGames_Banners_320x73.jpg";

//=----------------------------------------------------------------------------
// Kitty
//=----------------------------------------------------------------------------

const KittyPitching = "https://cdn.greenlinepet.com/games/assets/GrandSlam/animations/sprites/Cat_Pitch_Sprite.png";
const KittySwingHit = "https://cdn.greenlinepet.com/games/assets/GrandSlam/animations/sprites/Cat_Swing_Hit_Sprite.png";
const KittySwingMiss = "https://cdn.greenlinepet.com/games/assets/GrandSlam/animations/sprites/Cat_Swing_Miss_Sprite.png";
const KittyRunSlide = "https://cdn.greenlinepet.com/games/assets/GrandSlam/animations/sprites/Cat_Run_Slide_Sprite.png";
const KittyHomeRun = "https://cdn.greenlinepet.com/games/assets/GrandSlam/animations/sprites/Cat_Homerun_Sprite.png";

const {
    frames:KITTY_PITCHING_SHEET_FRAMES,
    duration:KITTY_PITCHING_SHEET_DURATION,
    heightScale:KITTY_PITCHING_SHEET_FRAME_SCALE_H,
} = setUpAnimation(13, DEFAULT_FRAME_COUNT_X, DEFAULT_FRAME_W, DEFAULT_FRAME_H);

export const KITTY_PITCHING_SHEET_ANIM = [{
    altText: "Kitty pitching",
    animationDuration: KITTY_PITCHING_SHEET_DURATION,
    duration: KITTY_PITCHING_SHEET_DURATION,
    canView: true,
    frames: KITTY_PITCHING_SHEET_FRAMES,
    lastFrame: KITTY_PITCHING_SHEET_FRAMES.length - 1,
    width: DEFAULT_FRAME_W_PX, // Width of the visible area in which the frames will appear (everything outside that is clipped).
    height: DEFAULT_FRAME_H_PX,
    left: "50%", // Container position offset within game animation view.
    top: "50%",
    image: KittyPitching,
    imageFrame: {
        heightScale: KITTY_PITCHING_SHEET_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: false,
}];

const {
    frames:KITTY_SWING_HIT_FRAMES,
    duration:KITTY_SWING_HIT_DURATION,
    heightScale:KITTY_SWING_HIT_FRAME_SCALE_H,
} = setUpAnimation(15, DEFAULT_FRAME_COUNT_X, DEFAULT_FRAME_W, DEFAULT_FRAME_H);

export const KITTY_SWING_HIT_ANIM = [{
    altText: "Kitty swing hit",
    animationDuration: KITTY_SWING_HIT_DURATION,
    duration: KITTY_SWING_HIT_DURATION,
    canView: true,
    frames: KITTY_SWING_HIT_FRAMES,
    lastFrame: KITTY_SWING_HIT_FRAMES.length - 1,
    width: DEFAULT_FRAME_W_PX,
    height: DEFAULT_FRAME_H_PX,
    left: "50%",
    top: "50%",
    image: KittySwingHit,
    imageFrame: {
        heightScale: KITTY_SWING_HIT_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: false,
}];

const {
    frames:KITTY_SWING_MISS_FRAMES,
    duration:KITTY_SWING_MISS_DURATION,
    heightScale:KITTY_SWING_MISS_FRAME_SCALE_H,
} = setUpAnimation(9, DEFAULT_FRAME_COUNT_X, DEFAULT_FRAME_W, DEFAULT_FRAME_H);

export const KITTY_SWING_MISS_ANIM = [{
    altText: "Kitty swing miss",
    animationDuration: KITTY_SWING_MISS_DURATION,
    duration: KITTY_SWING_MISS_DURATION,
    canView: true,
    frames: KITTY_SWING_MISS_FRAMES,
    lastFrame: KITTY_SWING_MISS_FRAMES.length - 1,
    width: DEFAULT_FRAME_W_PX,
    height: DEFAULT_FRAME_H_PX,
    left: "50%",
    top: "50%",
    image: KittySwingMiss,
    imageFrame: {
        heightScale: KITTY_SWING_MISS_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: false,
}];

const {
    frames:KITTY_RUN_SLIDE_FRAMES,
    duration:KITTY_RUN_SLIDE_DURATION,
    heightScale:KITTY_RUN_SLIDE_FRAME_SCALE_H,
} = setUpAnimation(41, DEFAULT_FRAME_COUNT_X, DEFAULT_FRAME_W, DEFAULT_FRAME_H);

export const KITTY_RUN_SLIDE_ANIM = [{
    altText: "Kitty pitching",
    animationDuration: KITTY_RUN_SLIDE_DURATION,
    duration: KITTY_RUN_SLIDE_DURATION,
    canView: true,
    frames: KITTY_RUN_SLIDE_FRAMES,
    lastFrame: KITTY_RUN_SLIDE_FRAMES.length - 1,
    width: DEFAULT_FRAME_W_PX, // Width of the visible area in which the frames will appear (everything outside that is clipped).
    height: DEFAULT_FRAME_H_PX,
    left: "50%", // Container position offset within game animation view.
    top: "50%",
    image: KittyRunSlide,
    imageFrame: {
        heightScale: KITTY_RUN_SLIDE_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: false,
}];

const {
    frames:KITTY_HOME_RUN_FRAMES,
    duration:KITTY_HOME_RUN_DURATION,
    heightScale:KITTY_HOME_RUN_FRAME_SCALE_H,
} = setUpAnimation(26, DEFAULT_FRAME_COUNT_X, DEFAULT_FRAME_W, DEFAULT_FRAME_H);

export const KITTY_HOME_RUN_ANIM = [{
    altText: "Puppy home run",
    animationDuration: KITTY_HOME_RUN_DURATION,
    duration: KITTY_HOME_RUN_DURATION,
    canView: true,
    frames: KITTY_HOME_RUN_FRAMES,
    lastFrame: KITTY_HOME_RUN_FRAMES.length - 1,
    width: DEFAULT_FRAME_W_PX,
    height: DEFAULT_FRAME_H_PX,
    left: "50%",
    top: "50%",
    image: KittyHomeRun,
    imageFrame: {
        heightScale: KITTY_HOME_RUN_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: false,
}];

const KITTY_HOME_RUN_FINISH_DURATION = getFrameDuration(1);

// This uses the same image and frames as the kitty home run animation.
export const KITTY_HOME_RUN_FINISH_ANIM = [{
    altText: "Kitty home run (finish)",
    animationDuration: KITTY_HOME_RUN_FINISH_DURATION,
    duration: KITTY_HOME_RUN_FINISH_DURATION,
    canView: true,
    frames: [
        // We just want the last frame.
        KITTY_HOME_RUN_FRAMES[KITTY_HOME_RUN_FRAMES.length - 1],
    ],
    lastFrame: 0,
    width: DEFAULT_FRAME_W_PX,
    height: DEFAULT_FRAME_H_PX,
    left: "50%",
    top: "50%",
    image: KittyHomeRun,
    imageFrame: {
        heightScale: KITTY_HOME_RUN_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: true, // This is the reason this animation exists - to show the doses after the home run animation finishes.
}];

//=----------------------------------------------------------------------------
// Puppy
//=----------------------------------------------------------------------------

const PuppyPitching = "https://cdn.greenlinepet.com/games/assets/GrandSlam/animations/sprites/Dog_Pitch_Sprite.png";
const PuppySwingHit = "https://cdn.greenlinepet.com/games/assets/GrandSlam/animations/sprites/Dog_Swing_Hit_Sprite.png";
const PuppySwingMiss = "https://cdn.greenlinepet.com/games/assets/GrandSlam/animations/sprites/Dog_Swing_Miss_Sprite.png";
const PuppyRunSlide = "https://cdn.greenlinepet.com/games/assets/GrandSlam/animations/sprites/Dog_Run_Slide_Sprite.png";
const PuppyHomeRun = "https://cdn.greenlinepet.com/games/assets/GrandSlam/animations/sprites/Dog_HomeRun_Sprite.png";

const {
    frames:PUPPY_PITCHING_SHEET_FRAMES,
    duration:PUPPY_PITCHING_SHEET_DURATION,
    heightScale:PUPPY_PITCHING_SHEET_FRAME_SCALE_H,
} = setUpAnimation(13, DEFAULT_FRAME_COUNT_X, DEFAULT_FRAME_W, DEFAULT_FRAME_H);

export const PUPPY_PITCHING_SHEET_ANIM = [{
    altText: "Puppy pitching",
    animationDuration: PUPPY_PITCHING_SHEET_DURATION,
    duration: PUPPY_PITCHING_SHEET_DURATION,
    canView: true,
    frames: PUPPY_PITCHING_SHEET_FRAMES,
    lastFrame: PUPPY_PITCHING_SHEET_FRAMES.length - 1,
    width: DEFAULT_FRAME_W_PX,
    height: DEFAULT_FRAME_H_PX,
    left: "50%",
    top: "50%",
    image: PuppyPitching,
    imageFrame: {
        heightScale: PUPPY_PITCHING_SHEET_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: false,
}];

const {
    frames:PUPPY_SWING_HIT_FRAMES,
    duration:PUPPY_SWING_HIT_DURATION,
    heightScale:PUPPY_SWING_HIT_FRAME_SCALE_H,
} = setUpAnimation(15, DEFAULT_FRAME_COUNT_X, DEFAULT_FRAME_W, DEFAULT_FRAME_H);

export const PUPPY_SWING_HIT_ANIM = [{
    altText: "Puppy swing hit",
    animationDuration: PUPPY_SWING_HIT_DURATION,
    duration: PUPPY_SWING_HIT_DURATION,
    canView: true,
    frames: PUPPY_SWING_HIT_FRAMES,
    lastFrame: PUPPY_SWING_HIT_FRAMES.length - 1,
    width: DEFAULT_FRAME_W_PX,
    height: DEFAULT_FRAME_H_PX,
    left: "50%",
    top: "50%",
    image: PuppySwingHit,
    imageFrame: {
        heightScale: PUPPY_SWING_HIT_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: false,
}];

const {
    frames:PUPPY_SWING_MISS_FRAMES,
    duration:PUPPY_SWING_MISS_DURATION,
    heightScale:PUPPY_SWING_MISS_FRAME_SCALE_H,
} = setUpAnimation(9, DEFAULT_FRAME_COUNT_X, DEFAULT_FRAME_W, DEFAULT_FRAME_H);

export const PUPPY_SWING_MISS_ANIM = [{
    altText: "Puppy swing miss",
    animationDuration: PUPPY_SWING_MISS_DURATION,
    duration: PUPPY_SWING_MISS_DURATION,
    canView: true,
    frames: PUPPY_SWING_MISS_FRAMES,
    lastFrame: PUPPY_SWING_MISS_FRAMES.length - 1,
    width: DEFAULT_FRAME_W_PX,
    height: DEFAULT_FRAME_H_PX,
    left: "50%",
    top: "50%",
    image: PuppySwingMiss,
    imageFrame: {
        heightScale: PUPPY_SWING_MISS_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: false,
}];

const {
    frames:PUPPY_RUN_SLIDE_FRAMES,
    duration:PUPPY_RUN_SLIDE_DURATION,
    heightScale:PUPPY_RUN_SLIDE_FRAME_SCALE_H,
} = setUpAnimation(41, DEFAULT_FRAME_COUNT_X, DEFAULT_FRAME_W, DEFAULT_FRAME_H);

export const PUPPY_RUN_SLIDE_ANIM = [{
    altText: "Puppy run slide",
    animationDuration: PUPPY_RUN_SLIDE_DURATION,
    duration: PUPPY_RUN_SLIDE_DURATION,
    canView: true,
    frames: PUPPY_RUN_SLIDE_FRAMES,
    lastFrame: PUPPY_RUN_SLIDE_FRAMES.length - 1,
    width: DEFAULT_FRAME_W_PX,
    height: DEFAULT_FRAME_H_PX,
    left: "50%",
    top: "50%",
    image: PuppyRunSlide,
    imageFrame: {
        heightScale: PUPPY_RUN_SLIDE_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: false,
}];

const {
    frames:PUPPY_HOME_RUN_FRAMES,
    duration:PUPPY_HOME_RUN_DURATION,
    heightScale:PUPPY_HOME_RUN_FRAME_SCALE_H,
} = setUpAnimation(27, DEFAULT_FRAME_COUNT_X, DEFAULT_FRAME_W, DEFAULT_FRAME_H);

export const PUPPY_HOME_RUN_ANIM = [{
    altText: "Puppy home run",
    animationDuration: PUPPY_HOME_RUN_DURATION,
    duration: PUPPY_HOME_RUN_DURATION,
    canView: true,
    frames: PUPPY_HOME_RUN_FRAMES,
    lastFrame: PUPPY_HOME_RUN_FRAMES.length - 1,
    width: DEFAULT_FRAME_W_PX,
    height: DEFAULT_FRAME_H_PX,
    left: "50%",
    top: "50%",
    image: PuppyHomeRun,
    imageFrame: {
        heightScale: PUPPY_HOME_RUN_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: false,
}];

const PUPPY_HOME_RUN_FINISH_DURATION = getFrameDuration(1);

// This uses the same image and frames as the puppy home run animation.
export const PUPPY_HOME_RUN_FINISH_ANIM = [{
    altText: "Puppy home run (finish)",
    animationDuration: PUPPY_HOME_RUN_FINISH_DURATION,
    duration: PUPPY_HOME_RUN_FINISH_DURATION,
    canView: true,
    frames: [
        // We just want the last frame.
        PUPPY_HOME_RUN_FRAMES[PUPPY_HOME_RUN_FRAMES.length - 1],
    ],
    lastFrame: 0,
    width: DEFAULT_FRAME_W_PX,
    height: DEFAULT_FRAME_H_PX,
    left: "50%",
    top: "50%",
    image: PuppyHomeRun,
    imageFrame: {
        heightScale: PUPPY_HOME_RUN_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: true, // This is the reason this animation exists - to show the doses after the home run animation finishes.
}];

//=----------------------------------------------------------------------------
// Umpire
//=----------------------------------------------------------------------------

const UmpireStrike = "https://cdn.greenlinepet.com/games/assets/GrandSlam/animations/sprites/Umpire_Strike_Sprite.png";
const UmpireSafe = "https://cdn.greenlinepet.com/games/assets/GrandSlam/animations/sprites/Umpire_Safe_Sprite.png";

const {
    frames:UMPIRE_SAFE_FRAMES,
    duration:UMPIRE_SAFE_DURATION,
    heightScale:UMPIRE_SAFE_FRAME_SCALE_H,
} = setUpAnimation(12, DEFAULT_FRAME_COUNT_X, DEFAULT_FRAME_W, DEFAULT_FRAME_H);

export const UMPIRE_SAFE_ANIM = [{
    altText: "Umpire safe",
    animationDuration: UMPIRE_SAFE_DURATION,
    duration: UMPIRE_SAFE_DURATION,
    canView: true,
    frames: UMPIRE_SAFE_FRAMES,
    lastFrame: UMPIRE_SAFE_FRAMES.length - 1,
    width: DEFAULT_FRAME_W_PX,
    height: DEFAULT_FRAME_H_PX,
    left: "50%",
    top: "50%",
    image: UmpireSafe,
    imageFrame: {
        heightScale: UMPIRE_SAFE_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: true,
}];

const {
    frames:UMPIRE_STRIKE_FRAMES,
    duration:UMPIRE_STRIKE_DURATION,
    heightScale:UMPIRE_STRIKE_FRAME_SCALE_H,
} = setUpAnimation(14, DEFAULT_FRAME_COUNT_X, DEFAULT_FRAME_W, DEFAULT_FRAME_H);

export const UMPIRE_STRIKE_ANIM = [{
    altText: "Umpire strike",
    animationDuration: UMPIRE_STRIKE_DURATION,
    duration: UMPIRE_STRIKE_DURATION,
    canView: true,
    frames: UMPIRE_STRIKE_FRAMES,
    lastFrame: UMPIRE_STRIKE_FRAMES.length - 1,
    width: DEFAULT_FRAME_W_PX,
    height: DEFAULT_FRAME_H_PX,
    left: "50%",
    top: "50%",
    image: UmpireStrike,
    imageFrame: {
        heightScale: UMPIRE_STRIKE_FRAME_SCALE_H,
    },
    isAnimated: true,
    showScore: true,
}];
