import React, { useState } from "react"

export default function PageStepper({
    pages = [],
    pageProps = {}, // Props you want to send to these pages.
    onComplete, // If there's no callback, the sequence will loop infinitely.
}) {
    const [index, setIndex] = useState(0);

    if (pages.length === 0) {
        if (!!onComplete) {
            onComplete();
        }

        return null;
    }

    const handleNext = () => {
        const nextIndex = index + 1;

        if (nextIndex === pages.length && !!onComplete) {
            onComplete();
        } else {
            setIndex(nextIndex % pages.length);
        }
    };

    const PageComponent = pages[index];

    return (
        <PageComponent
            onNext={handleNext}
            {...pageProps}
        />
    );
}
