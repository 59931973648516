import React, { useRef, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import styles from "./ClinicGamePage.scss";
import * as ClinicActions from "actions/ClinicActions";
import AccessDenied from "components/common/AccessDenied";
import BiProductDisclosures from "components/common/BiProductDisclosures";
import ClinicBase from "components/layout/ClinicBase";
import GenericGame from "components/games/elements/GenericGame/GenericGame";
import GenericGame2024 from "components/games/elements/GenericGame/GenericGame2024";
import GrandSlamGame from "./GrandSlamGame";
import GoldenSummerGames from "./GoldenSummerGames";
import { GoldenSummerLeaderboardLegalWording } from "components/games/elements/LegalWording/GoldenSummerLegalWording";
import { PuppyAndKittyBowlLeaderboardLegalWording } from "components/games/elements/LegalWording";
import Loader from "components/common/Loader";
import PrintContentContainer from "components/common/PrintContentContainer/PrintContentContainer";
import PuppyBowl2 from "./PuppyBowl2";
import PuppyBowlGame from "./PuppyBowlGame";
import PuppyLeafParty from "./PuppyLeafParty";
import PuppyPoolParty from "./PuppyPoolParty";
import { getClinicInfo, getClinicGameById } from "utils/ClinicData";
import getPrimaryClinicIdForUser from "utils/getPrimaryClinicIdForUser";
import { PermissionTypes, userHasPermission, userPrimaryLocationType } from "utils/permissions/rolesPermissions";
import { CLINIC_TAGS } from "constants/AdminControls";
import {
    PRINTABLE_GAMES,
    GRAND_SLAM,
    GOLDEN_SUMMER,
    LEAF_PARTY,
    PUPPY_BOWL_2,
    PUPPY_BOWL,
    PUPPY_POOL_PARTY
} from "constants/Games/GameConstants";
import * as LocationTypes from "constants/LocationTypes";
import * as UserPermissions from "constants/UserPermissions";
import {GrandSlamLeaderboardLegalWording} from "../../elements/LegalWording/GrandSlamLegalWording";

function PrintGamePage(props) {
    const {
        clinicId,
        game,
        isProvider = false,
        printableContentRef,
    } = props;

    const [printing, setPrinting] = useState(false);

    const gameProperties = useMemo(() => {
        if (game) {
            switch (game.gameType) {
                case PUPPY_BOWL_2:
                    return {
                        bannerUrl: "https://cdn.greenlinepet.com/games/assets/petsbowl/logos/Clinic_Rep_Leaderboard_Pets_Bowl_Banner_Image.jpg",
                        bannerAlt: "HeartGard® Plus+ and NexGard® Plus+ advertisement",
                        board: PuppyBowl2,
                        legalWording: PuppyAndKittyBowlLeaderboardLegalWording,
                        usePoints: false,

                    };
                case GOLDEN_SUMMER:
                    return {
                        bannerUrl: "https://cdn.greenlinepet.com/games/assets/GoldenGames/GoldenGames Banner_970x90_r1v1.jpg",
                        bannerAlt: "Golden Summer Games",
                        board: GoldenSummerGames,
                        legalWording: GoldenSummerLeaderboardLegalWording,
                        usePoints: true,
                    };

                case GRAND_SLAM:
                    return {
                        bannerUrl: "https://cdn.greenlinepet.com/games/assets/GrandSlam/BIAHU-004763_CMK_2024_FallGreenlineGames_Banners_970x89.jpg",
                        bannerAlt: "Grand Slam Game",
                        board: GrandSlamGame,
                        legalWording: GrandSlamLeaderboardLegalWording,
                        usePoints: true,
                    };
                default:
                // Do nothing
            }
        }
        return null;
    }, [game]);


    const renderGameSection = () => {
        switch(game.gameType) {
            case PUPPY_BOWL:
                return (
                    <PuppyBowlGame
                        game={game}
                        clinicId={clinicId}
                        animate
                    />
                );
            case PUPPY_POOL_PARTY:
                return (
                    <PuppyPoolParty
                        game={game}
                        clinicId={clinicId}
                        animate={!printing}
                    />
                );
            case LEAF_PARTY:
                return (
                    <PuppyLeafParty
                        animate={!printing}
                        clinicId={clinicId}
                        game={game}
                        isProvider={isProvider}
                        printing={printing}
                    />
                );
            case PUPPY_BOWL_2:
            case GOLDEN_SUMMER:
                return (
                    <GenericGame
                        animate={!printing}
                        clinicId={clinicId}
                        game={game}
                        bannerAd={gameProperties.bannerUrl}
                        bannerAdAltText={gameProperties.bannerAlt}
                        Leaderboard={gameProperties.board}
                        LegalWording={gameProperties.legalWording}
                        printing={printing}
                        usePoints={gameProperties.usePoints}
                    />
                );
            case GRAND_SLAM:
                return (
                    <GenericGame2024
                        animate={!printing}
                        clinicId={clinicId}
                        game={game}
                        bannerAd={gameProperties.bannerUrl}
                        bannerAdAltText={gameProperties.bannerAlt}
                        Leaderboard={gameProperties.board}
                        LegalWording={gameProperties.legalWording}
                        printing={printing}
                        usePoints={gameProperties.usePoints}
                        onlyPaid
                    />
                );
            default:
                return (
                    <GenericGame
                        game={game}
                        clinicId={clinicId}
                        animate={!printing}
                        printing={printing}
                    />
                );
        }
    }

    return (
        <PrintContentContainer
            onSetIsPrinting={setPrinting}
            allowPrint={PRINTABLE_GAMES[game.gameType]}
            outsideContentRef={printableContentRef}
        >
            <div className={styles.content}>
                {renderGameSection()}
            </div>
        </PrintContentContainer>
    );
}

function ClinicGamePage(props) {
    const {
        canViewClinicBIGamesDashboard = false,
        clinicId,
        game,
        getClinicGreenlineGames,
    } = props;
    const printableContentRef = useRef();

    useEffect(() => {
        if (canViewClinicBIGamesDashboard && !game) {
            getClinicGreenlineGames(clinicId);
        }
    }, [canViewClinicBIGamesDashboard, game, clinicId]);

    const pageTitle = useMemo(() => {
        switch(game?.gameType) {
            case PUPPY_BOWL:
                return `Greenline Games™ - Puppy Bowl ${new Date().getFullYear()}`;
            case PUPPY_POOL_PARTY:
                return `Greenline Games™ - Puppy Pool Party ${new Date().getFullYear()}`;
            case LEAF_PARTY:
                return `Greenline Games™ - Puppy Leaf Party ${new Date().getFullYear()}`;
            default:
                return `${game?.name}`;
        }
    }, [game]);

    if (!game) {
        return <Loader />
    }

    return (
        <ClinicBase
            pageTitle={pageTitle}
            clinicId={clinicId}
            adminControls={CLINIC_TAGS}
            collapsible
        >
            <div className={styles.root}>
                {canViewClinicBIGamesDashboard ? (
                    <div ref={printableContentRef}>
                        <PrintGamePage
                            {...props}
                            printableContentRef={printableContentRef}
                        />
                        <div className="text-x-sm border-top margin-bottom-sm">
                            <BiProductDisclosures/>
                        </div>
                    </div>
                ) : (
                    <AccessDenied/>
                )}
            </div>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo?.clinicId;
        const gameId = Number(ownProps.match.params.gameId);
        const userProfile = state.user.userProfile;
        const isProvider = userPrimaryLocationType(userProfile, [LocationTypes.PROVIDER]);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            canViewClinicBIGamesDashboard,
            clinicId,
            game: getClinicGameById(state, gameId),
            gameClinicId: Number(clinicId || getPrimaryClinicIdForUser(state.user.userProfile)),
            gameId,
            isProvider,
            snapshots: state.entities.mostRecentGameSnapshotDate,
        }
    },
    (dispatch) => ({
        getClinicGreenlineGames: (clinicId) => dispatch(ClinicActions.getGreenlineGames(clinicId)),
    }),
)(ClinicGamePage);
