import forEach from "lodash/forEach";


export const ADVANTAGE_PLUS = "Advantage Plus";
export const AVIMARK = "AVI Mark";
export const CLIENTRAX = "Clientrax";
export const CORNERSTONE = "Cornerstone";
export const DAYSMART_VETTER = "Daysmart/Vetter";
export const DAYSMART = "Daysmart";
export const VETTER = "Vetter";
export const DIGITAILS = "DigiTails";
export const DVM_MANAGER = "DVM Manager";
export const DVMAX = "DVMax";
export const EVET_PULSE = "Evet";
export const EZYVET  = "EzyVet";
export const HIPPO = "Hippo";
export const IMPROMED_INFINITY = "Impromed/Infinity";
export const IMPROMED = "Impromed";
export const INFINITY = "Infinity";
export const INTRAVET = "Intravet";
export const NEO = "Idexx Neo";
export const PROVET_CLOUD = "Provet Cloud";
export const SHEPHERD = "Shepherd";
export const VTECH_PLATINUM = "VTech";
export const VTECH_PRIME = "Prime";
export const VETBADGER = "VetBadger";
export const VETSPIRE = "Vetspire";
export const VIA = "VIA";

export const ALL_PIMS = [
    ADVANTAGE_PLUS,
    AVIMARK,
    CLIENTRAX,
    CORNERSTONE,
    DIGITAILS,
    DVM_MANAGER,
    DVMAX,
    EVET_PULSE,
    EZYVET,
    HIPPO,
    INFINITY,
    IMPROMED,
    INTRAVET,
    NEO,
    PROVET_CLOUD,
    SHEPHERD,
    VETTER,
    VTECH_PLATINUM,
    VTECH_PRIME,
    VETBADGER,
    VETSPIRE,
    VIA,
];

// All Help Text renders as: <li>{HELP_TEXT[PIMS].text} OR Contact <a href={HELP_TEXT[PIMS].url} target="_blank">{HELP_TEXT[PIMS].pimsName} support</a></li>
export const HELP_TEXT = {
    [ADVANTAGE_PLUS] : {
        text: "Your Advantage Plus manual",
        url: null,
        pimsName: "Advantage Plus",
    },
    [AVIMARK]: {
        text: "Your AVI mark manual located in the Help section",
        url: "https://software.covetrus.com/support/avimark-support/",
        pimsName: "AVI mark",
    },
    [CLIENTRAX] : {
        text: "Your Clientrax manual",
        url: null,
        pimsName: "Clientrax",
    },
    [CORNERSTONE] : {
        text: "Your Cornerstone manual",
        url: "https://www.idexx.com/en/veterinary/software-services/cornerstone-software/current-customers/",
        pimsName: "Cornerstone",
    },
    [VETTER] : {
        text: "Your Vetter manual",
        url: "https://help.vettersoftware.com/en/",
        pimsName: "Vetter",
    },
    [DIGITAILS] : {
        text: "Your DigiTails manual",
        url: null,
        pimsName: "DigiTails",
    },
    [DVM_MANAGER] : {
        text: "Your DVM Manager manual",
        url: null,
        pimsName: "DVM Manager",
    },
    [DVMAX] : {
        text: "Your DVMax manual",
        url: null,
        pimsName: "DVMax",
    },
    [EZYVET] : {
        text: "Your EzyVet manual",
        url: "https://www.ezyvet.com/support#tbc",
        pimsName: "EzyVet",
    },
    [EVET_PULSE]: {
        text: "Your Evet manual",
        url: "https://support.evetpractice.com/hc/en-us/articles/4401784503963-How-can-I-reach-Pulse-technical-support",
        pimsName: "Evet",
    },
    [HIPPO] : {
        text: "Your Hippo manual",
        url: null,
        pimsName: "Hippo",
    },
    [IMPROMED] : {
        text: "Your Impromed manual",
        url: "https://software.covetrus.com/support/impromed-support/",
        pimsName: "Impromed",
    },
    [INFINITY] : {
        text: "Your Infinity manual",
        url: "https://software.covetrus.com/support/impromed-support/",
        pimsName: "Infinity",
    },
    [INTRAVET] : {
        text: "Your manual",
        url: null,
        pimsName: "Intravet",
    },
    [NEO] : {
        text: "Your Neo manual",
        url: "https://www.idexx.com/en/veterinary/software-services/neo/current-customers/",
        pimsName: "Neo",
    },
    [PROVET_CLOUD] : {
        text: "Your Provet Cloud manual",
        url: null,
        pimsName: "Provet",
    },
    [SHEPHERD] : {
        text: "Your Shepherd manual",
        url: "https://www.shepherd.vet/contact/",
        pimsName: "Shepherd",
    },
    [VTECH_PLATINUM] : {
        text: "Your Vtech Platinum manual",
        url: null,
        pimsName: "Vtech Platinum",
    },
    [VTECH_PRIME] : {
        text: "Your Vtech Prime manual",
        url: null,
        pimsName: "Vtech Prime",
    },
    [VETBADGER] : {
        text: "Your VetBadger manual",
        url: null,
        pimsName: "VetBadger",
    },
    [VETSPIRE] : {
        text: "Your Vetspire manual",
        url: null,
        pimsName: "Vetspire",
    },
    [VIA] : {
        text: "Your Via manual",
        url: null,
        pimsName: "Via",
    }
}

export function getPimsType(clinicPimsInfo) {
    let pimsType = "";
    if (clinicPimsInfo) {
        forEach(ALL_PIMS, PIMS => {
            if (clinicPimsInfo.indexOf(PIMS) > -1) {
                pimsType = PIMS;
                return false;
            }
        });
    }
    return pimsType;
}
