import React from "react";
import PageRoot from "content/packages/ETSetupWalkthrough/pages/PageRoot";
import { DARK_GREEN, LIGHT_GREEN } from "content/packages/ETSetupWalkthrough/constants";
import CalloutBox from "content/packages/ETSetupWalkthrough/common/CalloutBox";
import NextButton from "content/packages/ETSetupWalkthrough/common/NextButton";
import ClientExperienceContainer from "content/packages/ETSetupWalkthrough/common/ClientExperienceContainer";

const THREE_STEPS_IMAGE = require("../images/steps.png");
const PREVIEW_BUTTON_IMAGE = require("../images/preview_experience_arrow.png");

export default function EnrollPage({
    onNext = () => undefined,
    onEnroll = () => undefined,
}) {
    return (
        <PageRoot>
            <ClientExperienceContainer pageTitle="Personalized Consumer Rebates by Boehringer Ingelheim">
                <div className="flex align-center">
                    {/* Spinny wheel */}
                    <div style={{ textAlign: "center", width: "80%", height: "100%" }}>
                        <h1 style={{ color: DARK_GREEN }}>Start playing now, with three simple steps</h1>
                        <img
                            style={{
                                width: "100%",
                                maxHeight: "45vh",
                                objectFit: "contain",
                            }}
                            src={THREE_STEPS_IMAGE}
                        />
                    </div>
                    {/* Instructions */}
                    <div style={{ width: "50%", height: "100%", maxHeight: "100%", fontSize: "1.1em" }}>
                        <CalloutBox style={{ width: "100%" }}>
                            <div className="flex-centered">
                                <h2 style={{ color: DARK_GREEN }}>Enhance the Game!</h2>
                            </div>
                            <div style={{ padding: "0 5%" }}>
                                <p>If you are also enrolled in Client Connection™ by Boehringer Ingelheim, your clients will receive communications via email or SMS so they can see their qualifying offers prior to their visit.</p>
                                <p>Talk to your Boehringer Ingelheim sales representative for more details.</p>
                                <div className="flex-centered margin-top-sm margin-bottom-sm">
                                    <div style={{ border: `2px solid ${LIGHT_GREEN}`, width: "50%" }} />
                                </div>
                                <p>Clients can claim an email offer by sharing the offer with you during checkout. All you need to do is edit the discount amount at checkout to match the presented offer.</p>
                            </div>
                            <div className="flex-centered">
                                <h2 style={{ color: DARK_GREEN }}>Not Ready to Play?</h2>
                            </div>
                            <div style={{ padding: "0 5%" }}>
                                <p>If clients do not present an offer at checkout, they can still save through the regular instant-rebate process.</p>
                            </div>
                        </CalloutBox>
                        <div
                            className="flex-centered"
                            style={{
                                marginTop: "1%",
                                fontSize: "2em",
                                // fontSize: "2.5vw",
                            }}
                        >
                            <a
                                href="#"
                                style={{ fontWeight: "bold", color: LIGHT_GREEN, textDecoration: "underline" }}
                                onClick={onEnroll}
                            >
                                Enroll Now
                            </a>
                        </div>
                        <NextButton
                            image={PREVIEW_BUTTON_IMAGE}
                            imageStyle={{ width: "40%" }}
                            altText="Preview button"
                            onNext={onNext}
                        />
                    </div>
                </div>
            </ClientExperienceContainer>
        </PageRoot>
    );
}
