import React, {
    // Comment out for generating the email scripts
    useContext,
    useMemo,
    useRef,
    useState
} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./JumbotronLeaderboard.scss";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import { addCommasToNumber } from "utils/numeric";
import { charLimit } from "utils/textUtils";
import {getEventNameForGameEventId} from "constants/Games/GameConstants";

// Comment out for generating the email scripts
import { windowSizeContext } from "AppRoot";
import InstantReplayAnimation from "components/games/elements/GrandSlamGame/InstantReplayAnimation";

export default function JumbotronLeaderboard({
    animate = false,
    backgroundImage,
    backgroundImageAlt,
    currentClinicId,
    currentEvent,
    data = [],
    daysRemaining,
    gameType,
    highlightTextColor = "#69FF8CFF",
    points = false,
    printing = false,
    showAllPoints = false,
    snapshot,
    onlyPaid,
textColor = "white",
}) {
    // Comment out for generating the email scripts
    const context = useContext(windowSizeContext) || {};
    const [gameLoaded, setGameLoaded] = useState(false);
    const sortedData = sortBy(data, "Place");
    const upToTen = [{}, {}, {}, {}, {}, {}, {}, {}].slice(0,10-sortedData.length);
    const topTenData = [ ...sortedData, ...upToTen ].slice(0, 10);
    const currentClinicData = !!currentClinicId ? find(sortedData, { ClinicId: currentClinicId }) : null;
    const currentClinicAfter10 = !!currentClinicData && currentClinicData.Place > 10;

    // Uncomment for generating the email scripts
    // const teamNameLimit = 50;

    // Comment out for generating the email scripts
    const teamNameLimit = useMemo(() => {
        if (context.windowWidth < 1470) {
            return 25;
        }
        return 50;
    }, [context]);

    const backgroundRef = useRef();

    const eventList = useMemo(() => {
        if (!data[0]?.eventTotals) {
            return null;
        }
        return map(data[0].eventTotals, eventInfo => ({
            name: getEventNameForGameEventId(gameType, eventInfo.EventId),
            id: eventInfo.EventId
        }));
    }, [data]);

    if (!data.length) return null;

    const currentEventIndex = useMemo(() => {
        if (eventList && currentEvent) {
            return findIndex(eventList, ["id", currentEvent.EventId]);
        }
        return -1;
    }, [eventList, currentEvent]);


    const renderEventPoints = (rowId, eventPoints, canViewPoints) => {
        return map(eventPoints, (pointCount, indx) => {
            const hasPoints = pointCount > -1;
            let pointText = <span>&nbsp;</span>;
            if (hasPoints && (currentEventIndex === -1 || currentEventIndex >= indx)) {
                if (canViewPoints) {
                    pointText = pointCount > 0 ? addCommasToNumber(pointCount) : <span>0</span>;
                } else {
                    pointText = "--";
                }
            }

            return (
                <div
                    className={classnames(styles.scoreBox, styles.eventTotal)}
                    key={`${rowId}_inning_${indx}`}
                >
                    {pointText}
                </div>
            );
        });
    }

    const renderTotalPoints = (points, canViewPoints) => {
        if (typeof(points) === "undefined") {
            return <span>&nbsp;</span>;
        }

        return canViewPoints ? addCommasToNumber(points) || 0 : "--";
    }

    const renderTeamDetails = (row) => {
        const rowHasContent = typeof(row.ClinicId) !== "undefined";
        const isCurrentTeam = rowHasContent && row.ClinicId === currentClinicId;
        const canViewPoints = rowHasContent && (isCurrentTeam || showAllPoints);

        let percentGrowthString = <span>&nbsp;</span>;
        if (rowHasContent) {
            if (row.PercentGrowth !== null && !isNaN(row.PercentGrowth)) {
                percentGrowthString = `${Math.round(100 + row.PercentGrowth).toFixed(0)}%`;
            } else {
                percentGrowthString = "--";
            }
        }

        // CURRENT EVENT INDEX .... look up index in eventTotals then, anything after currentEvent is empty
        let eventPoints = map(eventList, (event, index) => {
            if (currentEventIndex === -1 || currentEventIndex >= index) {
                if (row.eventTotals) {
                    if (points) {
                        return onlyPaid ? row.eventTotals[index].TotalPaidPoints : row.eventTotals[index].TotalPoints;
                    }
                    return onlyPaid ? row.eventTotals[index].TotalPaidDoses : row.eventTotals[index].TotalDoses;
                }
                return -1;
            }
            return null;

        });
        if (!eventPoints.length) {
            eventPoints = [];
        }

        return (
            <div
                id={`content_${row.Place}`}
                className={classnames(styles.rowScoresContainer, styles.rowContent, {
                    [styles.rowCurrentTeam]: isCurrentTeam,
                })}
                style={{
                    color: (isCurrentTeam && highlightTextColor)  ? highlightTextColor : textColor,
                }}
            >
                <div className={classnames(styles.scoreBox, styles.teamName)}>
                    {row.Place && <span className={styles.place}>{row.Place}.</span>}
                    {row.name? charLimit(row.name, teamNameLimit) : <span>&nbsp;</span>}
                </div>
                <div className={styles.eventTotals}>
                    {renderEventPoints(row.id, eventPoints, canViewPoints)}
                </div>
                {points ? (
                    <div className={classnames(styles.scoreBox, styles.doseTotals)}>{renderTotalPoints(row.Points, canViewPoints)}</div>
                ) : (
                    <div className={classnames(styles.scoreBox, styles.doseTotals)}>{renderTotalPoints(row.Doses, canViewPoints)}</div>
                )}
                <div className={classnames(styles.scoreBox, styles.vlsdPerc)}>{percentGrowthString}</div>
            </div>
        )
    };

    const renderEventLabels = () => {
        return map(eventList, (eventObj, index) => (
            <div
                key={`event_${eventObj.id}_label`}
                className={classnames(styles.scoreBox, styles.eventTotal)}
            >
                {eventObj.name}
            </div>
        ));
    };

    const handleImageLoad = (evt) => setGameLoaded(true);

    return (
        <div
            ref={backgroundRef}
            id="background-container"
            className={styles.backgroundContainer}
        >
            <div className={styles.backgroundSizer}>
                <div className={styles.backgroundImageSizer} >
                    <img
                        className={styles.backgroundImage}
                        src={backgroundImage}
                        alt={backgroundImageAlt}
                        onLoad={handleImageLoad}
                    />
                    <div
                        id="background-content"
                        className={classnames(styles.root, styles.backgroundContent)}
                    >
                        <div className={classnames(styles.rows, {
                            [styles.snapshot]: snapshot,
                        })}>
                            <div className={classnames(styles.row, styles.columnNames)}>
                                <div className={classnames(styles.scoreBox, styles.teamName)}>Team Names</div>
                                <div className={styles.eventTotals}>
                                    {renderEventLabels()}
                                </div>
                                <div className={classnames(styles.scoreBox, styles.doseTotals)}>Total {points ? "Points" : "Doses"}</div>
                                <div className={classnames(styles.scoreBox, styles.vlsdPerc)}>VLSD</div>
                            </div>
                            {map(topTenData, (row, index) => (
                                <div
                                    key={row.ClinicId}
                                    id={row.Place}
                                    className={classnames(styles.row, {
                                        [styles.rowSmaller]: currentClinicAfter10,
                                    })}
                                >
                                    {renderTeamDetails(row, index)}
                                </div>
                            ))}
                            {/* Conditionally shown if the current clinic is not in the top 10 */}
                            {currentClinicAfter10 && (
                                <div
                                    id="current_clinic_rank_above_10"
                                    className={classnames(styles.row, {
                                        [styles.rankAbove10Hidden]: !currentClinicAfter10,
                                        [styles.printing]: printing,
                                    })}
                                >
                                    {renderTeamDetails(currentClinicData, 10)}
                                </div>
                            )}
                        </div>
                        <div className={styles.daysToGo}>{daysRemaining}</div>
                        {/* Comment out for generating the email scripts */}
                        {animate && gameLoaded &&(
                            <InstantReplayAnimation />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

JumbotronLeaderboard.propTypes = {
    animate: PropTypes.bool,
    backgroundImage: PropTypes.string.isRequired,
    backgroundImageAlt: PropTypes.string.isRequired,
    borderColor: PropTypes.string,
    currentClinicId: PropTypes.number,
    currentEvent: PropTypes.object,
    data: PropTypes.array,
    daysRemaining: PropTypes.number,
    goal: PropTypes.number,
    gameType: PropTypes.string,
    highlightTextColor: PropTypes.string,
    lineColor: PropTypes.string,
    onAnimationComplete: PropTypes.func,
    points: PropTypes.bool, // If this is true, the game uses points otherwise, it uses doses.
    printing: PropTypes.bool,
    showAllPoints: PropTypes.bool,
    snapshot: PropTypes.bool,
    textColor: PropTypes.string,
    onlyPaid: PropTypes.bool,
};
