import PageFooter from "content/packages/ETSetupWalkthrough/common/PageFooter";
import React from "react";

const BI_LOGO_IMAGE = require("../images/BI-Logo-2024_W_rsg_white.png");

export default function BiFooter({ style = {} }) {
    return (
        <PageFooter style={{ fontSize:"0.65em", ...style }}>
            <div className="flex flex-centered padding-sm spaced-content">
                <div style={{ marginRight: "32px", width: "140px" }}>
                    <img
                        style={{ width: "100%" }}
                        src={BI_LOGO_IMAGE}
                    />
                </div>
                <div>
                    <div>
                        HEARTGARD&reg; and the Dog & Hand logo&reg; are registered trademarks of Boehringer Ingelheim Animal Health USA Inc. NexGard&reg; is a registered trademark and NexGard COMBO&trade; is a trademark of Boehringer Ingelheim Animal Health France, used under license. All other trademarks shown are the property of their respective owner.
                    </div>
                    <div>
                        &copy;2024 Boehringer Ingelheim Animal Health USA Inc., Duluth, GA. All rights reserved. US-PET-0864-2024
                    </div>
                </div>
            </div>
        </PageFooter>
    );
};
