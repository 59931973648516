import React from "react";
import { PALE_TAN } from "content/packages/ETSetupWalkthrough/constants"

export default function CalloutBox({
    style = {},
    children,
}) {
    return (
        <div style={{ width: "100%", padding: "3%", fontSize: "0.8em", backgroundColor: PALE_TAN, ...style }}>
            {children}
        </div>
    );
}
